<template>
  <div class="infolist">
    <div class="common_page_title">
      <div class="guwidth">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/redianzixun' }"
            >热点资讯主页</el-breadcrumb-item
          >
          <el-breadcrumb-item>资讯列表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>

    <div class="infobox flex base_w">
      <div class="common_index_leftwidth">
        <div class="common_title_box">
          <span class="title">全部文章</span>
        </div>
        <newstu :list="list"></newstu>
        <div class="paginationblock">
        <el-pagination
          :hide-on-single-page="true"
          @current-change="handleCurrentChange"
          :current-page.sync="pagination.currentPage"
          :page-size="pagination.size"
          layout="total, prev, pager, next"
          :total="pagination.total">
        </el-pagination>
      </div>

        <div class="bg16">
          <div class="btnbox">立即提问</div>
        </div>
      </div>
      <div class="common_index_rightwidth">
        <!-- 热门文章 -->
        <hotlist :list="inhighlist" @refresh="refresh"></hotlist>

        <!-- 优秀律师推荐  -->
        <div class="rmls">
          <div class="common_title_box">
            <span class="title">优秀律师推荐</span>
          </div>
          <hotls :list="Lawlist"></hotls>
        </div>
        <!-- 最新咨询 -->
        <div class="newzixun">
          <div class="common_title_box">
            <span class="title">最新咨询</span>
          </div>
          <div class="newziitem">
            <wenda :type="3" :wendalistArr="wentypedalist"></wenda>
            <div class="btnbox" @click.stop="tourl('/toask')">我要提问</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import  newstu from  '@/components/newstu'
import hotls from '@/components/hotls'
import wenda from '@/components/wenda'
import hotlist from '@/components/hotlist'

import apiUrl from '@/api/main'
export default {
  components: { newstu,hotls, wenda, hotlist },
  data() {
    return {
      wentypedalist:[], // 问答列表
      Lawlist:[], // 律师列表
      inhighlist:[], // 浏览量高

      list:[],
      pagination:{
        size:10, // 每页几条
        currentPage: 1,
        total: 0
      }
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.gettypewendalist(); // 问答
    this.lsLawyerlist(); // l律师
    this.getHighlist(); // 最新文章
    this.getall();
    // 跳转回顶部
    document.documentElement.scrollTop = 0;
  },
  methods: {
      // 跳转
      tourl(url) {
      this.$router.push({
        path: url
      })
    },
    refresh(){
      this.getdetail();
      this.gettypewendalist(); // 问答
      this.lsLawyerlist(); // l律师
      this.getHighlist(); // 最新文章
    },
     // 获取律师列表
     lsLawyerlist(){
      let data = { 
        pageNum: 1,
        pageSize: 3,
        orderByColumn: " ",
        isAsc: "desc",
      }
      apiUrl.lsLawyerindex(data).then((res) => {

          res.rows.forEach(element => {
            if(element.goodsIds){
              element.goodsIdsArr = element.goodsIds.split(',')
            }
          });
          this.Lawlist = res.rows;

      })
    },
      // 获取问答列表
      gettypewendalist() {
        let data = {
          pageSize: 2,
          pageNum: 1,
        }
        apiUrl.wendalist(data).then((res) => {

            this.wentypedalist = res.rows;

        })
      },
    // 浏览量高的文章
    getHighlist(){
      let data = {
        pageSize: 10,
        pageNum: 1,
        orderByColumn:'browseNum',
        isAsc:'desc'
      }
      apiUrl.lsInformationlist(data).then((res) => {
        this.inhighlist = res.rows
      })
    },

    // 全部文章
    getall(){
      let data = {
        pageSize: this.pagination.size,
        pageNum: this.pagination.currentPage,
        // orderByColumn:'createTime',
        // isAsc:'desc'
      }
      apiUrl.lsInformationlist(data).then((res) => {
        this.list = res.rows;
        this.pagination.total = res.total;
      })

    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val
      this.getall();
    }
  },

}
</script>
<style lang='scss' scoped>
.infolist {
  .infobox{
    padding-top: 40px;
    padding-bottom: 67px;
  }
  // 优秀律师推荐
  .rmls {
    margin-top: 33px;
    .common_title_box {
      margin-bottom: 13px;
    }
  }

  // 最新咨询
  .newzixun {
    margin-top: 33px;
    .common_title_box {
      margin-bottom: 13px;
    }
    .newziitem {
      // width: 488px;
      width: 586px;
      background: #f8f8f8;
      border-radius: 10px;
      padding: 0 27px 27px;
      .btnbox {
        width: 153px;
        height: 40px;
        line-height: 40px;
        border-radius: 30px;
        margin: 0 auto;
      }
    }
  }

  .bg16 {
      background: url('../../assets/img/bg16.png') no-repeat;
      background-size: 100% 100%;
      height: 152px;
      margin-top: 67px;
      display: flex;
      .btnbox {
        width: 152px;
        height: 38px;
        line-height: 38px;
        border-radius: 19px;
        margin: 0px auto;
        margin-top: 94px;
        font-size: 17px;
      }
    }
}
</style>