import { render, staticRenderFns } from "./infolist.vue?vue&type=template&id=0811e64f&scoped=true&"
import script from "./infolist.vue?vue&type=script&lang=js&"
export * from "./infolist.vue?vue&type=script&lang=js&"
import style0 from "./infolist.vue?vue&type=style&index=0&id=0811e64f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0811e64f",
  null
  
)

export default component.exports